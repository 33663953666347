import "./styles/base.css"
import "./styles/zendesk.css"

import CssBaseline from "@material-ui/core/CssBaseline"
import { MuiThemeProvider } from "@material-ui/core/styles"
import Citizen from "components/Citizen"
import * as dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import ReactDOM from "react-dom"
import { initDB } from "react-indexed-db"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { BrowserRouter } from "react-router-dom"

import reportWebVitals from "./reportWebVitals"
import theme from "./themes/main"

// Extend dayjs to use UTC
dayjs.extend(utc)

// React query queryClient
// queries option will stop retry if reponse status is 401
const queryClient = new QueryClient({
  defaultOptions: {
    refetchOnWindowFocus: false,
    queries: {
      staleTime: 1000 * 60 * 5,
      retry: (failureCount, error) => {
        if (
          error &&
          error & error.hasOwnProperty("response") &&
          error.response.hasOwnProperty("status")
        ) {
          return error.response.status >= 500
        } else {
          return false
        }
      },
    },
  },
})
initDB({
  name: "match-board-auth",
  version: 1,
  objectStoresMeta: [
    {
      store: "auth",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "token", keypath: "token" },
        { name: "account", keypath: "account" },
      ],
    },
  ],
})

const Application = (
  <BrowserRouter
    basename={process.env.NODE_ENV !== "development" ? "" : "/citizen"}
  >
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <Citizen />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </MuiThemeProvider>
  </BrowserRouter>
)

ReactDOM.render(Application, document.getElementById("root"))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
