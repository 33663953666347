import Snackbar from "components/Common/Snackbar"
import Unauthorized from "components/Common/Unauthorized"
import { AppContainer } from "contexts/App"
import { CitizenAppContainer } from "contexts/Citizen/CitizenApp"
import { MessagesContainer } from "contexts/Messages"
import ClickConfirmation from "dialogs/ClickConfirmation"
import LoginDialog from "dialogs/Login"
import { useState } from "react"
import { useEffect } from "react"
import { useHistory, withRouter } from "react-router"
import Router, { citizenRouteConfig } from "router"

import useStyles from "./styles"

const Citizen = () => {
  const { classes } = useStyles()
  const history = useHistory()

  const [isIframe, setIsIframe] = useState(false)

  // sets to true if entry point into app is through iframe
  // false if entering app from normal entry
  useEffect(() => {
    if (history?.location?.pathname.includes("/pub")) {
      setIsIframe(true)
    }
  }, [history?.location?.pathname])

  return (
    <AppContainer isClerk={false} isIframe={isIframe}>
      <CitizenAppContainer>
        <MessagesContainer>
          <Router routes={citizenRouteConfig} />
          <div id="dialog-holder">
            {/* LEAVE THIS IN PLACE FOR DIALOGS TO POPULATE INTO */}
            <LoginDialog />

            <ClickConfirmation />
          </div>
          <Snackbar />
          <Unauthorized />
        </MessagesContainer>
      </CitizenAppContainer>
    </AppContainer>
  )
}

export default withRouter(Citizen)
